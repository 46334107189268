<template>
  <div>
    <b-skeleton
      v-if="spinner"
      type="input"
      class="mb-2"
    />
    <b-row
      v-else
    >
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="MapIcon"
          :statistic="dataMainDashboard.totalVenues ? dataMainDashboard.totalVenues : 0"
          statistic-title="Total Venue(s)"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="MaximizeIcon"
          color="success"
          :statistic="dataMainDashboard.totalDevices ? dataMainDashboard.totalDevices : 0"
          statistic-title="Total Screens"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="CalendarIcon"
          color="danger"
          :statistic="dataMainDashboard.totalPmpsCount ? dataMainDashboard.totalPmpsCount : 0"
          statistic-title="Total Campaigns"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="AirplayIcon"
          color="warning"
          :statistic="dataMainDashboard.totalAds ? dataMainDashboard.totalAds : 0"
          statistic-title="Total Ads"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        lg="6"
        sm="6"
      >
        <b-skeleton-img
          v-if="spinner"
          height="190px"
        />
        <b-card
          v-else
          class="card-congratulation-medal"
          @click="pushToVenue"
        >
          <div class="d-flex justify-content-start pb-1">
            <h5>Top Rated Venue</h5>
          </div>
          <b-card-text
            class="font-large-1 font-weight-bold"
          >
            <div
              class="cursor-pointer text-primary cursor-pointer zoom-hover text-truncate pb-1"
              style="max-width: 300px;"
            >
              <span
                v-b-tooltip.hover
                :title="dataMainDashboard.topRatedVenue.venue_name ? dataMainDashboard.topRatedVenue.venue_name : '--'"
              >
                {{ dataMainDashboard.topRatedVenue.venue_name ? dataMainDashboard.topRatedVenue.venue_name : '--' }}
              </span>
            </div>
          </b-card-text>
          <div class="d-flex flex-column justify-content-between align-items-start ">
            <span class="d-flex align-items-center">
              <p class="mb-75 mt-1 font-large-1 font-weight-bolder pr-1 pb-1">
                {{ dataMainDashboard.topRatedVenue.campaign_count_on_this_venue ? dataMainDashboard.topRatedVenue.campaign_count_on_this_venue : '--' }}
              </p> <span v-if="dataMainDashboard.topRatedVenue.campaign_count_on_this_venue">Campaigns on this venue</span>
            </span>
            <!-- <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              View Venue
            </b-button> -->
            <b-img
              :src="require('@/assets/images/images/badge.svg')"
              class="congratulation-medal"
              alt="Medal Pic"
            />
          </div></b-card>
      </b-col>
      <b-col
        lg="6"
        sm="6"
      >
        <b-skeleton-img
          v-if="spinner"
          height="190px"
        />
        <b-card
          v-else
          class="card-congratulation-medal"
          @click="pushToTopRatedScreen"
        >
          <div class="d-flex justify-content-start pb-1">
            <h5>Top Rated Screen</h5>
          </div>
          <b-card-text
            class="font-large-1 font-weight-bold"
          >
            <div
              class="cursor-pointer text-primary cursor-pointer zoom-hover text-truncate pb-1"
              style="max-width: 300px;"
            >
              <span
                v-b-tooltip.hover
                :title="dataMainDashboard.topRatedScreen ? dataMainDashboard.topRatedScreen.name : '--'"
              >
                {{ dataMainDashboard.topRatedScreen ? dataMainDashboard.topRatedScreen.name : '--' }}
              </span>
            </div>
          </b-card-text>
          <div class="d-flex flex-column justify-content-between align-items-start ">
            <span class="d-flex align-items-center">
              <p class="mb-75 mt-1 font-large-1 font-weight-bolder pr-1 pb-1">
                {{ dataMainDashboard.topRatedScreen ? dataMainDashboard.topRatedScreen.ad_count_on_this_screen : '--' }}
              </p>
              <span v-if="dataMainDashboard.topRatedScreen">Ads on this screen</span>
            </span>
            <!-- <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              View Screen
            </b-button> -->
          </div>
          <b-img
            :src="require('@/assets/images/images/personalization.svg')"
            class="congratulation-medal-personalization"
            alt="Medal Pic"
            height="150px"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        lg="12"
        sm="12"
        class="mt-2"
      >
        <b-card>
          <CardAgencyVenueDashboard
            :simi-network="true"
            class="p-25"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        lg="12"
        sm="12"
        class="mt-2"
      >
        <b-card>
          <ShowVenueList />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText,
  //   BButton,
  BImg,
  BSkeletonImg,
  BSkeleton,
  VBTooltip,
} from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import ShowVenueList from '@/components/simiNetwork/ShowVenueList.vue'
import { showToast } from '@/common/global/functions'
import CardAgencyVenueDashboard from '@/components/common/CardAgencyVenueDashboard.vue'
import AccountTypes from '@/common/enums/accountTypeEnum'

export default {
  components: {
    BRow,
    BCol,
    StatisticCardHorizontal,
    BCard,
    BCardText,
    // BButton,
    BImg,
    ShowVenueList,
    BSkeletonImg,
    BSkeleton,
    CardAgencyVenueDashboard,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      spinner: false,
      dataMainDashboard: {
        totalVenues: 0,
        totalDevices: 0,
        totalPmpsCount: 0,
        totalAds: 0,
        topRatedVenue: {},
      },
    }
  },
  computed: {
    isUserAdmin() {
      return this.$store.getters['user/getUserRole'] === AccountTypes.ADMIN
    },
  },
  mounted() {
    this.getMainDashboardAnalytics()
  },
  methods: {
    async getMainDashboardAnalytics() {
      let response
      try {
        this.spinner = true
        if (this.isUserAdmin) {
          response = await this.$axios.get('/admin/pmp-ad/simi-network-stats', {
            headers: {
              userHasGroupId: this.$route.params.userId,
            },
          })
        } else {
          response = await this.$axios.get('/pmp-ads/simi-network-stats')
        }
        this.dataMainDashboard = response.data.data
      } catch (error) {
        console.error('error', error.message)
        showToast('SIMI Network', error.response.data.message ? error.response.data.message : error.message, 'danger', 4000)
      } finally {
        this.spinner = false
      }
    },
    pushToTopRatedScreen() {
      this.spinner = true
      this.$router.push(`/simi-network/screen/${this.dataMainDashboard.topRatedScreen.id}`)
      this.spinner = false
    },
    pushToVenue() {
      this.spinner = true
      this.$router.push(`/simi-network/venue/${this.dataMainDashboard.topRatedVenue.id}`)
      this.spinner = false
    },
  },

}
</script>

<style>
.congratulation-medal{
    position: absolute;
    right: 0 !important;
    top: 0 !important;
    bottom: 100px !important;
    left: 25rem !important;
}
.congratulation-medal-personalization{
    position: absolute !important;
    right: 0 !important;
    top: 1.25rem !important;
    bottom: 0rem !important;
    left: 15rem !important;
}
.zoom-hover {
    padding-left: 5px;
    transition: transform 0.2s ease-in-out !important;
    transform-origin: left center;
  }

  .zoom-hover:hover {
    transform: scale(1.1) !important;
  }
</style>
